import accountApi from '@/api/modules/main-server/account'
import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'
import { useOrganizationStore } from './organization'

export const useAccountStore = defineStore({
  id: 'account',

  state: () => ({
    account: null,
    email: '',
    password: '',
    accessToken: null,
    userID: null,
    tokenRefresher: null,
    quotas: []
  }),

  persist: {
    storage: localStorage,
    paths: ['userID', 'tokenRefresher']
  },

  getters: {
    isLoggedIn(state) {
      if (!state.account || !state.accessToken) {
        return false
      }
      const tokenExpiration = jwtDecode(state.accessToken).exp
      return tokenExpiration * 1000 >= Date.now()
    },
    getAccessToken: (state) => state.accessToken,

    getUsername: ({ account }) => account?.name,
    getUsernameFirstLetter: ({ account }) => account?.name.charAt(0).toUpperCase()
  },

  actions: {
    async setup() {
      let accessToken = this.getAccessToken

      if (!accessToken) {
        await this.renewToken().catch(() => {})
      }

      accessToken = this.getAccessToken

      if (!accessToken) {
        return
      }

      await this.getDetails()
    },
    register(data, captcha) {
      return accountApi.register(data, captcha)
    },
    resetPassword(data, captcha) {
      return accountApi.resetPassword(data, captcha)
    },
    changePassword(data) {
      return accountApi.changePassword(data)
    },
    changePasswordFromToken(data, captcha) {
      return accountApi.changePasswordFromToken(data, captcha)
    },
    verify(data, captcha) {
      return accountApi.verify(data, captcha)
    },
    changeEmail(data) {
      return accountApi.changeEmail(data)
    },
    login(formData, captcha) {
      return new Promise((resolve, reject) => {
        accountApi.login(formData, captcha).then(
          (response) => {
            this.onLogin(response).then(() => resolve())
          },
          (error) => reject(error)
        )
      })
    },
    onLogin(response, loadDetails = true) {
      this.resetTokenRefresher()
      this.dropCredentials()
      const decodedToken = jwtDecode(response.data.access_token)
      const tokenExpiration = decodedToken.exp
      // const userID = +decodedToken.sub.replace('user:', '')
      // remember connected user id
      // this.setUserID(userID)
      // remember user token (and id)
      this.setToken(response.data.access_token)
      // setup token refresh
      this.setRefresher(tokenExpiration)

      if (loadDetails) {
        const organizationStore = useOrganizationStore()
        return new Promise((resolve) => {
          Promise.allSettled([this.getDetails(), organizationStore.getOrganizations()]).then(() => {
            resolve(this.account)
          })
        })
      } else {
        return new Promise((resolve) => {
          resolve(this.account)
        })
      }
    },
    getAuthorizeCodeSSOURL(payload) {
      return accountApi.getAuthorizeCodeSSOURL(payload.provider)
    },
    authorizeSSO(payload) {
      return new Promise((resolve, reject) => {
        accountApi.authorizeSSO(payload.provider, payload.code, payload.state).then(
          (response) => {
            this.onLogin(response)
              .then((userData) => {
                resolve(userData)
              })
              .catch((error) => reject(error))
          },
          (error) => reject(error)
        )
      })
    },
    setRefresher(tokenExpiration) {
      // compute timeout in milliseconds
      const timeout = tokenExpiration * 1000 - Date.now()
      this.setTokenRefresher(
        setTimeout(() => {
          this.renewToken()
        }, timeout)
      )
    },
    renewToken() {
      // erase refresher
      this.resetTokenRefresher()
      // perform renewal
      return new Promise((resolve, reject) => {
        accountApi
          .renewToken()
          .then(
            (response) => {
              // new token
              this.setToken(response.data.access_token)
              // (re)set token refresher
              const tokenExpiration = jwtDecode(response.data.access_token).exp
              this.setRefresher(tokenExpiration)
              // continue
              resolve(response)
            },
            (error) => {
              // TODO allow user to retry to not loose everything ?
              this.logout()
              reject(error)
            }
          )
          .catch(() => {})
      })
    },
    getQuotas() {
      return new Promise((resolve, reject) => {
        accountApi.getQuotas().then(
          (response) => {
            this.setQuotas(response.data.quotas)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    logout() {
      return accountApi
        .logout()
        .catch(() => {})
        .finally(() => {
          // finally, in all cases, drop all token and user data
          this.resetTokenRefresher()
          this.dropToken()
          this.dropUserID()
          this.dropCredentials()
        })
    },

    setToken(accessToken) {
      this.accessToken = accessToken
    },
    setQuotas(quotas) {
      this.quotas = quotas
    },

    dropCredentials() {
      this.email = ''
      this.password = ''
    },
    dropToken() {
      this.accessToken = null
    },
    dropUserID() {
      this.userID = 0
    },

    setTokenRefresher(tokenRefresher) {
      this.tokenRefresher = tokenRefresher
    },
    resetTokenRefresher() {
      if (this.tokenRefresher) {
        clearTimeout(this.tokenRefresher)
        this.tokenRefresher = null
      }
    },

    /**
     * Get details of the user account.
     * @returns {Promise<AxiosResponse>}
     */
    getDetails() {
      return new Promise((resolve) => {
        accountApi.getDetails().then(({ data }) => {
          this.account = data
          resolve(data)
        })
      })
    },

    /**
     * Modification of the user account.
     * @param {Object} data `name`, `email`, `receive_newsletter`
     * @returns {Promise<AxiosResponse>}
     */
    editDetails(data) {
      return new Promise((resolve, reject) => {
        accountApi.editDetails(data).then(
          (res) => {
            this.account = res.data
            resolve(res.data)
          },
          (error) => reject(error)
        )
      })
    },

    /**
     * Delete user account.
     * All their data will be erased.
     * @returns {Promise<AxiosResponse>}
     */
    delete() {
      return accountApi.delete()
    }
  }
})
