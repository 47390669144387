import axiosInstance from '@/api'
import EventBus from '@/common/eventBus'
import accountApi from '@/api/modules/main-server/account'
import { useAccountStore } from '@/store/account'
import { useOrganizationStore } from '@/store/organization'
import axios from 'axios'

const doNotCancel = [
  /sso\.json$/,
  /providers\.json$/,
  /pricing_plans\.json$/,
  /organizations\/[0-9]+\/members\/[0-9]+$/
]

/**
 * Checks if an axios request is cancellable.
 * @param {axios.InternalAxiosRequestConfig} config
 * @returns {Boolean}
 */
export const isCancellable = (config) => {
  const { url } = config

  for (const pattern of doNotCancel) {
    if (url.match(pattern)) {
      return false
    }
  }

  return true
}

export const setupInterceptor = () => {
  const accountStore = useAccountStore()

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = accountStore.getAccessToken
      if (accessToken) {
        // inject the token in request headers
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      if (isCancellable(config)) {
        config.signal = window.abortController.signal
        window.launchedRequests.push(axios.getUri(config))
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  // ensure we have an Authentication header (access token)
  axiosInstance.interceptors.response.use(
    (result) => {
      return result
    },
    (error) => {
      // error because we are missing the Authentication header

      // request got cancelled
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      //
      const originalConfig = error.config || {}

      // trying to refresh token if possible
      // Access Token was maybe expired

      // set a flag to not loop infinitely
      originalConfig._retry = true

      if (error?.response == null) {
        // not an API error
        return Promise.reject(error)
      }

      if (error.response.status !== 401) {
        // not an API authentication error
        return Promise.reject(error)
      }

      if (originalConfig.url === accountApi.loginUrl) {
        // error during a login or a token refresh attempt
        //  attempt a proper logout in case it is needed
        EventBus.dispatch('logout')
        return Promise.reject(error)
      }

      if (originalConfig._retry) {
        // an attempt to refresh the token was already made and failed.
        //  so from there, we know we are not logged in anymore
        return Promise.reject(error)
      }

      accountStore.renewToken().then(
        () => {
          // continue and retry now that we got a fresh token
          return Promise.resolve(axiosInstance(originalConfig))
        },
        (refreshError) => {
          // user might have been disconnected
          //   send the logout event through the entire app
          EventBus.dispatch('logout')
          return Promise.reject(refreshError)
        }
      )
    }
  )
}

export const setupCurrentUser = async () => {
  const accountStore = useAccountStore()
  const organizationStore = useOrganizationStore()

  // load expected current user if any
  await accountStore.setup()

  if (!accountStore.isLoggedIn) {
    // no user or not authenticated anymore
    return
  }

  // load more shit about the user
  await organizationStore.getOrganizations()
}
